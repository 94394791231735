import React, { useContext } from "react"
import "./_success-page-download.scss"
import { string_translation, createMarkup } from "../../../utils"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import {Link, useStaticQuery, graphql} from "gatsby"

function SucessPagesDownload(props) {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      goBack_ar:translateString(language: AR, string: "الصفحة الرئيسية")
      goBack_en:translateString(language: EN, string: "Back Home")

      home_ar:translateString(language: AR, string: "HOMEPAGE NAME")
      home_en:translateString(language: EN, string: "HOMEPAGE NAME")

      title1_ar:translateString(language: AR, string: "شكراً لك!")
      title1_en:translateString(language: EN, string: "Thank You!")

      title2_ar:translateString(language: AR, string: "تم ارسال الملف عبر البريد الالكتروني")
      title2_en:translateString(language: EN, string: "We've sent the file to your email")


      title3_ar:translateString(language: AR, string: "يرجى الاطلاع على صندوق الوارد!")
      title3_en:translateString(language: EN, string: "Please check your inbox!")
    }
  }`)

  return (
    <>
      <section className="success-prompt-download">
        <div className="container">
          <div className="success-wrapper">
            <div className="img-wrapper desktop-show">
              <img src={props.image1} alt={ string_translation(stringTranslations,"title1", locale)} />
            </div>
            <div className="img-wrapper mobile-show">
              <img src={props.image1_mobile} alt={ string_translation(stringTranslations,"title1", locale)} />
            </div>
            <div className="big-text-title" >
              <span dangerouslySetInnerHTML={createMarkup(
                      string_translation(stringTranslations,"title1", locale)
                    )}>
                    </span>
            </div>
            <br />
            <div className="big-text">{string_translation(stringTranslations,"title2", locale)}
           
<p>{props.email}</p>
          
            {string_translation(stringTranslations,"title3", locale)}
            </div>
            <div className="back-home">
           
              <Link to={"/"+locale} className="click-home">
                {" "}
                &#8592;  {string_translation(stringTranslations,"goBack", locale)}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default SucessPagesDownload
